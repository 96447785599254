import React from "react"
import "../assets/css/main.scss"
import { URL } from "../constants/Url"
import logoNyalanesia from "../assets/images/nyalanesia_bg.png"
import DipsyLogo from "../assets/images/dipsy_logo.png"
import { Button, Container } from 'react-bootstrap'

class WelcomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {  }
    }
    render() {  
        return (
            <div className="welcome-page wrapper">
                <Container>
                    <div className="welcome-page-body">
                        <img src={logoNyalanesia} alt="logoNyalanesia" />
                        <p className="welcome-page-title-label">Selamat datang di</p>
                        <img src={DipsyLogo} alt="dispyLogo" className="dipsy-logo" />
                        <p className="welcome-page-description">Melalui platform Dipsy, menerbitkan menjadi semudah menjentikkan jari. Dipsy adalah Digital Publishing System yang dirancang Nyalanesia, untuk membantu siswa, guru, ataupun penulis untuk mengirim, menerbitkan, berkonsultasi, mengecek progres, hingga memesan cetak buku dengan hanya berbekal usapan jari. Semuanya ada dalam satu platform yang terintegrasi.</p>
                    </div>
                    <div className="welcome-page-title-footer">
                        <div class="button-footer">
                        <Button onClick={() => this.props.history.push(URL.REGISTER)}>DAFTAR</Button>
                        <p>Sudah memiliki akun? <Button variant="link" onClick={() => this.props.history.push(URL.LOGIN)}>Masuk</Button></p>
                        </div>
                        <div className="form-footer"></div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default WelcomePage