import React, { useState } from "react";
import { useGoogleLogin } from "react-google-login";
import * as CredentialProject from '../../../json/clientId.json'
import logoGoogle from "../../../assets/images/googleLogo.svg"
import { postLoginGoogle } from "../../../actions/auth";
import { cksClient } from "../../../helper";
import { URL } from "../../../constants/Url";
import { useHistory } from "react-router";
import moment from "moment";
const clientId = CredentialProject.default.web.client_id
const LoginWithGoogle = ({ onError }) => {
  const [onLoad, setOnload] = useState(false)
  const [textButton, settextButton] = useState('Masuk dengan Google')
  const history = useHistory()
  // var textButton = 'Memuat....'
  const onSuccess = (res) => {
    var profileObj = res.profileObj
    // console.log('loginSuccess currentUser:', res.profileObj)
    var data = new FormData()
    data.set('google_id', profileObj.googleId)
    data.set('email', profileObj.email)

    postLoginGoogle(data, res => {
      const { data } = res
      cksClient().set('_authToken', data.data.access_token, {
          path: '/',
          sameSite: 'Lax',
          expires: 21600
      })
      return history.push(URL.PROGRAM_REGISTERED)
    }, err =>{
      onError(true);
      setOnload(false)
      settextButton('Masuk dengan Google')
    })
  }

  const onFailure = (res) => {
    setOnload(false)
    settextButton('Masuk dengan Google')
    console.log('LoginFailed', res)
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: false,
    accessType: 'offline'
  })

  const signUpData = () => {
    setOnload(true)
    settextButton('Memuat...')
    console.log(onLoad)
    console.log(textButton)
    signIn()
  }

  return (
    <button onClick={signUpData} disabled={onLoad} className="btn-google">
      <img src={logoGoogle}></img>
      {textButton}
    </button>
  )
}

export default LoginWithGoogle