import React from 'react'
import "../../assets/css/main.scss"
import { Alert, Button, Form, Navbar } from 'react-bootstrap'
import { Formik } from 'formik'
import { get_listLocation } from '../../actions/master'
import { initFormRegister, valid_formSignUp } from './common/const'
import { serializeForm } from '../../helper'
import { URL } from '../../constants/Url'
import { post_registerUser } from '../../actions/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { cksClient } from '../../helper'
import { CustomInfoMessage } from "../../components/custom"
import RegisterWithGoogle from './components/RegisterGoogle'
import Caretdown from '../../assets/images/icon/caret_down.svg'
import Logo from "../../assets/images/nav_logo.png"
import { NavLink as RouterLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import $ from 'jquery'
import "daterangepicker/moment.min.js"
import "daterangepicker/daterangepicker.js"
import "daterangepicker/daterangepicker.css"
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css"
import "select2/dist/js/select2.full.min.js"
import "select2/dist/css/select2.min.css"

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            formRegister: {
                ...initFormRegister,
                url: window.location.origin,
                isLoading: false
            },
            locationData: {
                province: [],
                kabupaten: [],
                selectedProvince: null,
                selectedKabupaten: null,
                isLoading: false,
            },
            optionData: {
                jenis_kelamin: [
                    {value: 'L', label: "Laki-Laki"},
                    {value: 'P', label: 'Perempuan'}
                ],
                pendidikan_terakhir: [
                    {value: 'sd', label: 'Sekolah Dasar (SD)'},
                    {value: 'smp', label: 'Sekolah Menengah Pertama (SMP)'},
                    {value: 'sma', label: 'Sekolah Menengah Atas (SMA)'},
                    {value: 'diploma3', label: 'Diploma-3 (D3)'},
                    {value: 'sarjana', label: 'Sarjana (S1)'},
                    {value: 'magister', label: 'Megister (S2)'},
                    {value: 'doktor', label: 'Doktor (S3)'}
                ],
                status: [
                    {value: 'Pelajar', label: 'Pelajar'},
                    {value: 'Guru', label: 'Guru'},
                    {value: 'Lainnya', label: 'Lainnya'},
                ]
            },
            info: {
                isShow: null,
                infoVariant: 'danger',
                infoBody: null,
            },
            showPassword: [
                false
            ],
            MySwal: withReactContent(Swal)
        }
    }

    // setUpdateDateBirth = (value) => {
    //     console.log(value)
    //     this.setState({
    //         formRegister: {
    //             tgl_lahir: value
    //         }
    //     })
    // }

    componentDidMount() {
        const {state} = this
        this.api_getListLocation()
        // console.log(CredentialProject.default.web.client_id)
        $('#dateBirth').daterangepicker({
            "showDropdowns": true,
            "singleDatePicker": true,
            "autoApply": true,
            "locale": {
                "format": "DD MMM YYYY",
                "separator": " - ",
                "applyLabel": "Apply",
                "cancelLabel": "Cancel",
                "fromLabel": "From",
                "toLabel": "To",
                "customRangeLabel": "Custom",
                "weekLabel": "W",
                "daysOfWeek": [
                    "Mg",
                    "Sn",
                    "Sl",
                    "Rb",
                    "Km",
                    "Jm",
                    "Sa"
                ],
                "monthNames": [
                    "Januari",
                    "Februari",
                    "Maret",
                    "April",
                    "Mei",
                    "Juni",
                    "Juli",
                    "Agutus",
                    "September",
                    "Oktober",
                    "November",
                    "Desember"
                ],
                "firstDay": 1
            },
            "startDate": new Date(),
            "endDate": new Date()
        }, (start, end) => {

            const input = document.getElementById('dateBirth')


            const lastValue = input.value;
          
            input.value = start.format('DD MMM YYYY');
          
            const event = new Event("input", { bubbles: true });
          
            const tracker = input._valueTracker;
          
            if (tracker) {
              tracker.setValue(lastValue);
            }
            input.dispatchEvent(event);
            // $('#dateBirth').val(start.format('YYYY-MM-DD')).trigger('change')
            // this.setUpdateDateBirth(start.format('YYYY-MM-DD'))
        });

        $('.select2WithSearch').select2({
            width: '100%',
            theme: 'bootstrap4'
        })

        $('.select2WithoutSearch').select2({
            width: '100%',
            theme: 'bootstrap4',
            minimumResultsForSearch: -1
        })
        $.each($('select'), (i, v) => {
            $(v).on('select2:select',function(e){
                var data = e.params.data;
                // console.log(e.currentTarget.getAttribute('name'))
                const input = e.currentTarget


                const lastValue = input.value;
              
                input.value = data.id;
              
                const event = new Event("change", { bubbles: true });
              
                const tracker = input._valueTracker;
                if (tracker) {
                    tracker.setValue(lastValue);
                  }
                  input.dispatchEvent(event);
                // $(e.currentTarget).val(data.id).trigger('change');
            });
        })
    }

    api_getListLocation = () => {
        const { state } = this
        const locationData = {...state.locationData}

        locationData.isLoading = true
        this.setState({
            locationData
        }, () => {
            get_listLocation(null, 
                resp => {
                    const { data } = resp

                    locationData.isLoading = false
                    locationData.province = data.data

                    this.setState({
                        locationData
                    })
                },

                err => {
                    locationData.isLoading = false
                    this.setState({
                        locationData
                    })
                }
            )
        })
    }
    
    click_buttonSubmit = (values, formikProps) => {
        const { props, state } = this
        const formData = serializeForm(initFormRegister, values, 'form-data')
        const formRegister = {...state.formRegister}

        formRegister.isLoading = true
        this.setState({
            formRegister
        }, () => {
            post_registerUser(formData,
                resp => {
                    const { data } = resp
                    formRegister.isLoading = false
                    this.setState({
                        formRegister
                    })

                    state.MySwal.fire({
                        title: 'SELAMAT!',
                        text: 'Pendaftaran akun anda berhasil, silakan cek folder inbox atau spam untuk memverifikasi akun',
                        width: 445,
                        showCancelButton: false,
                        showCancelButton: false,
                        icon: 'success'
                    }).then(() => {
                        return props.history.push(URL.LOGIN)
                    })

                    // cksClient().set('_authToken', data.data.access_token, {
                    //     path: '/',
                    //     sameSite: 'Lax'
                    // })
                    // return props.history.push(URL.PROGRAM_REGISTERED)
                },

                err => {
                    const data = err.data
                    if (data) {
                        if(data.errors && data.errors.email){
                            this.set_toggleInfo(true, 'danger', data.errors.email[0] ?? 'Please check your data again')
                            formikProps.setFieldError('email', data.errors.email[0] ?? 'Please check your data again')
                        }
                        if(data.errors && data.errors.password){
                            this.set_toggleInfo(true, 'danger', data.errors.password[0] ?? 'Please check your data again')
                            formikProps.setFieldError('password', data.errors.password[0] ?? 'Please check your data again')
                        }
                    }

                    formRegister.isLoading = false
                    this.setState({
                        formRegister
                    })
                }
            )
        })

    }

    selectedProvince = (value, formikProps) => {
        const { state } = this
        const locationData = {...state.locationData}
        const formRegister = {...formikProps}

        locationData.isLoading = true
        this.setState({
            locationData
        }, () => {
            let selected = locationData.province.filter(x => x.id_provinsi === Number(value))[0]

            locationData.isLoading = false
            locationData.selectedProvince = value
            locationData.kabupaten = selected.kabupaten
            formRegister.provinsi_id = value
            this.setState({
                locationData,
                formRegister,
            })
        })
    }

    selectedKabupaten = (value, formikProps) => {
        const formRegister = {...formikProps}

        formRegister.kabupaten_id = value
        this.setState({
            formRegister
        })
    }

    set_toggleInfo = (nextState, infoVariant, infoBody) => {
        this.setState({
            info: {
                isShow: nextState,
                infoVariant: infoVariant,
                infoBody: infoBody
            }
        })
    }

    showingPassword = (key) => {
        const { state } = this
        var showing = state.showPassword
        showing[key] = !showing[key]
        this.setState({
            showPassword: showing
        })
    }

    render() {
        const { state } = this

        return (
            <>
                <div className="registration-page wrapper">
                <Navbar bg="red" expand="lg" className="navbar-header justify-content-end">
                    <RouterLink to={URL.WELCOMEPAGE}>
                        <img src={Logo} alt="gmb logo" className="bg-brand"></img>
                    </RouterLink>
                </Navbar>
                    <header style={{paddingLeft: '40px'}}>
                        <h1>Pendaftaran Akun</h1>
                    </header>
                    <div className="registration-form container py-3 pb-5">
                            {
                                state.info.isShow ?
                                <CustomInfoMessage 
                                    show={state.info.isShow}
                                    variant={state.info.infoVariant}
                                    header={state.info.infoVariant}
                                    body={state.info.infoBody}
                                />
                                :
                                <Alert variant="danger">
                                    <span>PERHATIAN!!</span>
                                    <br/>
                                    <span>Dimohon untuk mengisi data dengan valid.</span>
                                </Alert>
                            }
                        <div className="googleContainer mb-4">
                            <RegisterWithGoogle />
                        </div>
                        <div className="custom-card p-3">
                            <Formik
                                initialValues={state.formRegister}
                                onSubmit={(values, formikProps) => this.click_buttonSubmit(values, formikProps)}
                                validationSchema={valid_formSignUp}
                                validateOnBlur={false}
                                validateOnChange={true}
                                >
                                {formikProps => (
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Nama Lengkap (*)</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Nama"
                                                name="nama"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.nama}
                                                error={formikProps.errors.nama}
                                                isInvalid={formikProps.errors.nama} />
                                        </Form.Group>
            
                                        <Form.Group className="position-relative">
                                            <Form.Label htmlFor="dateBirth">Tanggal Lahir (*)</Form.Label>
                                            <Form.Control 
                                                type="input" 
                                                placeholder="Tanggal Lahir"
                                                name="tgl_lahir"
                                                id="dateBirth"
                                                onChange={(e) => {
                                                    // console.log(e.target.value)
                                                    formikProps.setFieldValue('tgl_lahir', e.target.value)
                                                }}
                                                readOnly={true}
                                                style={{background: '#fff'}}
                                                value={formikProps.values.tgl_lahir}
                                                error={formikProps.errors.tgl_lahir}
                                                isInvalid={formikProps.errors.tgl_lahir} />
                                                <Form.Label className="icon-form" htmlFor="dateBirth"><img src={Caretdown} alt="caretup"></img></Form.Label>
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Jenis Kelamin (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="jenis_kelamin"
                                                data-placeholder="Jenis Kelamin"
                                                onChange={(e) => formikProps.setFieldValue('jenis_kelamin', e.target.value)}
                                                value={formikProps.values.jenis_kelamin}
                                                error={formikProps.errors.jenis_kelamin}
                                                isInvalid={formikProps.errors.jenis_kelamin}
                                                className="select2WithoutSearch" >
                                                    <option></option>
                                                    <option value="L">Laki - Laki</option>
                                                    <option value="P">Perempuan</option>
                                            </Form.Control>
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Pendidikan Terakhir (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="pendidikan_terakhir"
                                                data-placeholder="Pendidikan Terakhir"
                                                onChange={(e) => formikProps.setFieldValue('pendidikan_terakhir', e.target.value)}
                                                value={formikProps.values.pendidikan_terakhir}
                                                error={formikProps.errors.pendidikan_terakhir}
                                                isInvalid={formikProps.errors.pendidikan_terakhir}className="select2WithoutSearch">
                                                    <option></option>
                                                    <option value="sd">Sekolah Dasar (SD)</option>
                                                    <option value="smp">Sekolah Menengah Pertama (SMP)</option>
                                                    <option value="sma">Sekolah Menengah Atas (SMA)</option>
                                                    <option value="diploma3">Diploma-3 (D3)</option>
                                                    <option value="sarjana">Sarjana (S1)</option>
                                                    <option value="magister">Magister (S2)</option>
                                                    <option value="doktor">Doktor (S3)</option>
                                            </Form.Control>
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Email (*)</Form.Label>
                                            <Form.Control 
                                                type="email" 
                                                placeholder="Email"
                                                name="email"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.email}
                                                error={formikProps.errors.email}
                                                isInvalid={formikProps.errors.email} />
                                        </Form.Group>
                                        
                                        <Form.Group>
                                            <Form.Label>Password (*)</Form.Label>
                                            <div class="position-relative">
                                                <Form.Control 
                                                    type={state.showPassword[0] ? 'text': 'password'} 
                                                    placeholder="Password"
                                                    name="password"
                                                    onChange={formikProps.handleChange}
                                                    value={formikProps.values.password}
                                                    error={formikProps.errors.password}
                                                    isInvalid={formikProps.errors.password} />
                                                    <span className="form-icon" onClick={(e) => this.showingPassword(0)}><FontAwesomeIcon icon={(state.showPassword[0]) ? faEyeSlash : faEye} /></span>
                                            </div>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Nomor Hp (*)</Form.Label>
                                            <Form.Control 
                                                type="number" 
                                                placeholder="Nomor Hp"
                                                name="nohp"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.nohp}
                                                error={formikProps.errors.nohp}
                                                isInvalid={formikProps.errors.nohp} />
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Link Sosial Media (*)</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Link Sosial Media"
                                                name="sosial_media"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.sosial_media}
                                                error={formikProps.errors.sosial_media}
                                                isInvalid={formikProps.errors.sosial_media} />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Provinsi (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="provinsi_id"
                                                className="select2WithSearch"
                                                data-placeholder="Pilih Provinsi"
                                                error={formikProps.errors.provinsi_id}
                                                isInvalid={formikProps.errors.provinsi_id}
                                                onChange={(e) => {
                                                        this.selectedProvince(e.target.value, formikProps.values)
                                                        return formikProps.setFieldValue('provinsi_id', e.target.value)
                                                    }
                                                }
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.provinsi_id}
                                                disabled={state.locationData.isLoading}>
                                                    <option value="" key="">{state.locationData.isLoading ? `Loading...` : `` }</option>
                                                    {!state.locationData.isLoading && state.locationData.province.map((value,key) => (
                                                        <option value={value.id_provinsi} key={key}>{value.nama_provinsi}</option>
                                                    ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Kabupaten (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="kabupaten_id"
                                                className="select2WithSearch"
                                                data-placeholder="Pilih Kabupaten"
                                                error={formikProps.errors.kabupaten_id}
                                                isInvalid={formikProps.errors.kabupaten_id}
                                                onChange={(e) => {
                                                        this.selectedKabupaten(e.target.value, formikProps.values)
                                                        return formikProps.setFieldValue('kabupaten_id', e.target.value)
                                                    }
                                                }
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.kabupaten_id}
                                                disabled={state.locationData.isLoading}>
                                                    <option value="" key="">{state.locationData.isLoading ? `Loading...` : `` }</option>
                                                    {!state.locationData.isLoading && state.locationData.kabupaten.map((value,key) => (
                                                        <option value={value.id_kota} key={key}>{value.nama_kota}</option>
                                                    ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Status (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="status"
                                                className="select2WithoutSearch"
                                                data-placeholder="Pilih Status"
                                                onChange={(e) => formikProps.setFieldValue('status', e.target.value)}
                                                value={formikProps.values.status}
                                                error={formikProps.errors.status}
                                                isInvalid={formikProps.errors.status} >
                                                    <option></option>
                                                    <option value="Pelajar">Pelajar</option>
                                                    <option value="Guru">Guru</option>
                                                    <option value="Lainnya">Lainnya</option>
                                            </Form.Control>
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Nama Instansi (*)</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Instansi"
                                                name="instansi"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.instansi}
                                                error={formikProps.errors.instansi}
                                                isInvalid={formikProps.errors.instansi} />
                                        </Form.Group>

                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Alamat</Form.Label>
                                            <Form.Control 
                                                as="textarea" 
                                                rows={3}
                                                placeholder="alamat"
                                                name="alamat"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.alamat}
                                                error={formikProps.errors.alamat}
                                                isInvalid={formikProps.errors.alamat} />
                                        </Form.Group>

                                        <Form.Group className="form-footer">
                                            <Button 
                                                type="submit"
                                                onClick={formikProps.handleSubmit}
                                                disabled={state.formRegister.isLoading}>
                                                    {state.formRegister.isLoading ? <FontAwesomeIcon icon={faSpinner} spin />  :'DAFTAR'}
                                            </Button>
                                        </Form.Group>
            
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Register