const API = {
    CHANGE_PASSWORD: '/api/ganti-password',
    REGISTER: '/api/pengguna/daftar',
    REGISTER_GOOGLE: '/api/pengguna/daftar/google',
    LOGIN: '/api/pengguna/login',
    LOGIN_GOOGLE: '/api/pengguna/login/google',
    FORGET: '/api/pengguna/forget',
    CHECK_FORGET: '/api/pengguna/check_forget',
    CHECK_VERIFIKASI: '/api/pengguna/check-verifikasi',
    CHANGE_PASSWORD_PENGGUN: '/api/pengguna/change-password',
    LOCATION: '/api/alamat',
    PROFILE: '/api/profil',
    PROFILE_UPDATE: '/api/profil/update',
    PROFILE_LIST_INVOICE: '/api/profil/invoice',
    PROFILE_LIST_NASKAH: '/api/profil/naskah',
    PROGRAM_LIST: '/api/program',
    PROGRAM_DETAIL: '/api/program/:slug',
    PROGRAM_QUESTION: '/api/program/pertanyaan/:slug',
    PROGRAM_TERDAFTAR: '/api/program/terdaftar/list',
    PROGRAM_TERDAFTAR_DETAIL: '/api/program/terdaftar/:slug',
    PROGRAM_INVOICE: '/api/program/pembayaran',
    PROGRAM_INFO_INVOICE: '/api/program/pembayaran_program/:slug',
    PROGRAM_MOU_INVOICE: '/api/program/invoice-mou/:slug',
    PROGRAM_UPLOAD_NASKAH: '/api/program/upload/naskah',
    PROGRAM_UPLOAD_NASKAH_PAKET: '/api/program/upload/naskah/paket',
    PEMBAYARAN_INFO: '/api/pembayaran',
    PROGRAM_LIST_NASKAH: '/api/program/naskah',
    PROGRAM_NASKAH_REVISI: '/api/program/naskah/revisi',
    PROGRAM_NASKAH_ACC: '/api/program/naskah/acc',
    PROGRAM_INVOICE_ONGKIR: '/api/program/pembayaran/ongkir',
    PROGRAM_TERIMA_BUKU: '/api/program/buku/terima',
    PROGRAM_NASKAH_DOWNLOAD: '/api/program/download/:slug',
    KODE_CHECK: '/api/program/kode/:kode/:type',
    DATA_ONGKIR: '/api/program/ongkir/:slug',
    PROGRAM_KWITANSI: 'api/kwitansi/:slug',
    MOU_FILE: 'api/mou/:slug',
    JUARA_SEKOLAH: 'api/pengumuman-juara/:slug',
    MOCKUP_BUKU: 'api/program/mockup/download/:slug'
}

const URL = {
    // auth url
    WELCOMEPAGE: '/',
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    FORGOT_PASSWORD: '/auth/forgot-password',
    FORGOT_PASSWORD_CHECK: '/forget/:slug',
    VERIFIKASI_CHECK: '/verifikasi/:slug',
    CHANGE_PASSWORD: '/change-password/:slug',

    PROFILE: '/app/profile',
    PROFILE_UPDATE: '/app/profile/update',
    PROFILE_CHANGE_PASSWORD: '/app/profile/change-password',
    PROGRAM: '/app/program',
    PROGRAM_DETAIL: '/app/program/:slug/detail',
    PROGRAM_FORM: '/app/program/:slug/form',
    PROGRAM_REGISTERED: '/app/program/registered',
    PROGRAM_REGISTERED_DETAIL: '/app/program/:slug/registered',
    PROGRAM_INVOICE: '/app/program/:slug/invoice',
    PROGRAM_MOU_INVOICE: '/app/program/:slug/mou-invoice',
    PROGRAM_UPLOAD_NASKAH: '/app/program/:slug/upload-naskah',
    PROGRAM_UPLOAD_NASKAH_PAKET: '/app/program/:slug/upload-naskah-paket',
    PROGRAM_UPSELLING: '/app/program/upselling',
    PROGRAM_UPSELLING_FORM: '/app/program/upselling/:slug/form',
    PROGRAM_SCRIPT: '/app/program/script/:slug',
    PROGRAM_ONGKIR_KONFIRMASI: '/app/program/payment/shipping/:slug',
    PROGRAM_SCRIPT_REVISI: '/app/program/revisi/:slug',

    PROFILE_PKS_INVOICE: '/app/profile/invoice',
    PROFILE_PKS_NASKAH: '/app/profile/naskah',
    PROFILE_PKS_VERIFIKASI: '/app/profile/verifikasi',
    PROFILE_PKS_PROSES: '/app/profile/proses',
    PROFILE_PKS_PENGIRIM: '/app/profile/pengirim'
}

export {
    API,
    URL,
}